import { FC, useState } from 'react';

import { Translate, useTranslation } from '@cms/i18n';
import { News, Pagination, Platform, PlatformID, SubjectID } from '@common/clients/api';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { DisplayType, NewsList } from '@web/molecules/NewsList';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { TagIntroBlock, TagIntroBlockProps, TagIntroBlockType } from '@web/molecules/TagIntroBlock';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';

import { fetchVideos } from '../VideoCategoryOverview';

export interface State {
    currentPage: number;
    pagination?: Pagination;
    generalVideos: News[];
    categoryVideos: News[][];
}

export interface Props {
    state: State;
}

const getTagIntroBlockData = (platform: Platform, __videos: Translate): TagIntroBlockProps => {
    let title = __videos(`${platform.id}-videos`);
    if (title === `${platform.id}-videos` || title === 'videos') {
        title = __videos`videos`;
    }

    if (platform.subject === SubjectID.MOTORSPORT) {
        return {
            type: TagIntroBlockType.COMMON,
            title: title,
            description: __videos`f1-description`,
        };
    } else if (platform.subject === SubjectID.FOOTBALL) {
        return {
            type: TagIntroBlockType.COMMON,
            superTitle: __videos`latest-videos`,
            title: __videos`football-videos`,
            description: __videos`description`,
        };
    }

    return {
        type: TagIntroBlockType.COMMON,
        title: title,
        description: __videos(`${platform.subject}-description`),
    };
};

type FilteredCategoryVideos = [News[], Array<News[]>] | [];
const getFilteredCategoryVideos = (
    categoryVideos: News[][],
    condition: (video: News) => boolean,
): FilteredCategoryVideos => {
    if (categoryVideos.length === 0) {
        return [];
    }

    const filteredCategoryVideos =
        categoryVideos.find((videosByCategory) => {
            return !!videosByCategory.length && condition(videosByCategory[0]);
        }) || [];

    const remainingCategoryVideos = categoryVideos.filter((videosByCategory) => {
        return !!videosByCategory.length && !condition(videosByCategory[0]);
    });

    return [filteredCategoryVideos, remainingCategoryVideos];
};

export const VideoOverview: FC<Props & DefaultServerSideProps> = ({ contextData, state: initialState }) => {
    const __videos = useTranslation('videos').t;
    const __meta = useTranslation('meta').t;

    const fallbackTitle = __videos('title');
    const platformTitle = __videos(`title-${contextData.platform.id}`);

    const isCustomTitle = !(
        platformTitle === `title-${contextData.platform.id}` || platformTitle === contextData.platform.id
    );
    const title = isCustomTitle ? platformTitle : fallbackTitle;

    const [state, setState] = useState<State>(initialState);

    const onReadMoreClick = async (): Promise<void> => {
        const newState: State = {
            ...state,
            currentPage: state.currentPage + 1,
        };

        const data = await fetchVideos(contextData, newState.currentPage, true);
        data?.data?.forEach((video: News) => {
            state.generalVideos.push(video);
        });

        newState.generalVideos = state.generalVideos || [];
        newState.pagination = data?.pagination;

        setState(newState);
    };

    const platform = contextData?.platform;
    const generalVideosProps =
        platform?.id === PlatformID.VI
            ? {
                  title: <h2>{__videos(`title-${platform.id}`)}</h2>,
              }
            : { skipDivider: true };

    const [proVideos, categoryVideos] = getFilteredCategoryVideos(
        state.categoryVideos,
        (video) => video?.category?.functionality === 'pro',
    );

    return (
        <>
            <MetaHead
                title={title}
                description={__meta(`video.description.${contextData.context.subject}`, {
                    domainName: contextData.context.name,
                })}
                isCopyrighted={false}
            />
            <div className="main-content">
                <PlatformToggle exclude={PlatformID.VI}>
                    <TagIntroBlock {...getTagIntroBlockData(contextData.platform, __videos)} />
                </PlatformToggle>
                <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_1} />
                <NewsList
                    {...generalVideosProps}
                    items={state.generalVideos}
                    pagination={state.pagination}
                    onReadMoreClick={onReadMoreClick}
                    displayType={DisplayType.blocks}
                />
                <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_2} />
                {categoryVideos && categoryVideos?.length > 0
                    ? categoryVideos.map((videosByCategory, index) => {
                          const newsCategory = videosByCategory[0].category;
                          const newsCatNameSlug = newsCategory?.slug;
                          const moreArticlesLink = newsCatNameSlug
                              ? `${__videos('url:videos')}/${newsCatNameSlug}`
                              : undefined;

                          return (
                              <NewsList
                                  key={`video-list-${index}`}
                                  title={<h2>{newsCategory?.name}</h2>}
                                  items={videosByCategory}
                                  displayType={DisplayType.carrousel}
                                  moreArticlesLink={moreArticlesLink}
                              />
                          );
                      })
                    : null}
                {proVideos && proVideos?.length > 0 ? (
                    <NewsList
                        title={
                            <h2>{`${__videos(`${platform?.id}.pro`).toUpperCase()} ${__videos('videos')}`}</h2>
                        }
                        items={proVideos}
                        displayType={DisplayType.carrousel}
                        moreArticlesLink={`${__videos('url:videos')}/${proVideos[0]?.category?.slug}`}
                        moreArticlesLinkText={__videos('more-link', {
                            label: __videos(`${platform?.id}.pro`).toUpperCase(),
                        })}
                    />
                ) : null}
            </div>
            <MatchTicker />
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
